export default {
    "games": [
        {
            "id": "273841",
            "name": "Animal Crossing: New Leaf - Welcome amiibo",
            "dateRelease": "2016-11-25T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "Nintendo",
            "publisher": "Nintendo",
            "categories": [
                "animal_life",
                "simulation"
            ],
            "gameSeries": [
                "animal_crossing"
            ]
        },
        {
            "id": "765384",
            "name": "Mario Kart 8",
            "dateRelease": "2014-05-30T00:00:00Z",
            "system": "Wii U",
            "developer": "Nintendo",
            "publisher": "Nintendo",
            "categories": [
                "party",
                "racing"
            ],
            "gameSeries": [
                "mario_kart"
            ]
        },
        {
            "id": "858506",
            "name": "Shovel Knight",
            "dateRelease": "2015-10-30T00:00:00Z",
            "system": "Wii U",
            "developer": "",
            "publisher": "Yacht Club Games",
            "categories": [
                "platformer"
            ],
            "gameSeries": []
        },
        {
            "id": "858507",
            "name": "Shovel Knight: Treasure Trove",
            "dateRelease": "2015-10-30T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Yacht Club Games",
            "categories": [
                "platformer"
            ],
            "gameSeries": []
        },
        {
            "id": "864329",
            "name": "Super Smash Bros. for Nintendo 3DS",
            "dateRelease": "2014-10-03T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "fighting"
            ],
            "gameSeries": [
                "super_smash_bros"
            ]
        },
        {
            "id": "864849",
            "name": "Super Smash Bros. for Wii U",
            "dateRelease": "2014-11-28T00:00:00Z",
            "system": "Wii U",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "fighting"
            ],
            "gameSeries": [
                "super_smash_bros"
            ]
        },
        {
            "id": "891849",
            "name": "Hyrule Warriors",
            "dateRelease": "2014-09-19T00:00:00Z",
            "system": "Wii U",
            "developer": "Koei Tecmo Games",
            "publisher": "Nintendo",
            "categories": [
                "action"
            ],
            "gameSeries": []
        },
        {
            "id": "892510",
            "name": "Splatoon",
            "dateRelease": "2015-05-29T00:00:00Z",
            "system": "Wii U",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "shooter"
            ],
            "gameSeries": [
                "splatoon"
            ]
        },
        {
            "id": "892537",
            "name": "Yoshi's Woolly World",
            "dateRelease": "2015-06-26T00:00:00Z",
            "system": "Wii U",
            "developer": "Good-Feel",
            "publisher": "Nintendo",
            "categories": [
                "platformer"
            ],
            "gameSeries": [
                "yoshi"
            ]
        },
        {
            "id": "892704",
            "name": "Super Mario Maker",
            "dateRelease": "2015-09-11T00:00:00Z",
            "system": "Wii U",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "platformer"
            ],
            "gameSeries": [
                "super_mario"
            ]
        },
        {
            "id": "892779",
            "name": "Mario Party 10",
            "dateRelease": "2015-03-20T00:00:00Z",
            "system": "Wii U",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "party"
            ],
            "gameSeries": [
                "mario_party"
            ]
        },
        {
            "id": "892923",
            "name": "Captain Toad: Treasure Tracker",
            "dateRelease": "2015-01-02T00:00:00Z",
            "system": "Wii U",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "platformer",
                "puzzle"
            ],
            "gameSeries": []
        },
        {
            "id": "893013",
            "name": "Kirby and the Rainbow Paintbrush",
            "dateRelease": "2015-05-08T00:00:00Z",
            "system": "Wii U",
            "developer": "HAL Laboratory, Inc.",
            "publisher": "Nintendo",
            "categories": [
                "platformer"
            ],
            "gameSeries": [
                "kirby"
            ]
        },
        {
            "id": "893260",
            "name": "The Legend of Zelda: Breath of the Wild",
            "dateRelease": "2017-03-03T00:00:00Z",
            "system": "Wii U",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "adventure"
            ],
            "gameSeries": [
                "the_legend_of_zelda"
            ]
        },
        {
            "id": "893949",
            "name": "Code Name: S.T.E.A.M.",
            "dateRelease": "2015-05-15T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "INTELLIGENT SYSTEMS",
            "publisher": "Nintendo",
            "categories": [
                "shooter",
                "strategy"
            ],
            "gameSeries": []
        },
        {
            "id": "954437",
            "name": "ACE COMBAT ASSAULT HORIZON LEGACY +",
            "dateRelease": "2015-02-13T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "Project Aces",
            "publisher": "BANDAI NAMCO Entertainment",
            "categories": [
                "action"
            ],
            "gameSeries": []
        },
        {
            "id": "961136",
            "name": "Xenoblade Chronicles 3D",
            "dateRelease": "2015-04-02T00:00:00Z",
            "system": "New Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "adventure",
                "rpg"
            ],
            "gameSeries": [
                "xenoblade_chronicles"
            ]
        },
        {
            "id": "986009",
            "name": "amiibo Touch & Play: Nintendo Classics Highlights",
            "dateRelease": "2015-04-30T00:00:00Z",
            "system": "Wii U",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "other"
            ],
            "gameSeries": []
        },
        {
            "id": "1009406",
            "name": "Animal Crossing: Happy Home Designer",
            "dateRelease": "2015-10-02T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "animal_life",
                "simulation"
            ],
            "gameSeries": [
                "animal_crossing"
            ]
        },
        {
            "id": "1021489",
            "name": "Chibi-Robo! Zip Lash",
            "dateRelease": "2015-11-06T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "platformer"
            ],
            "gameSeries": []
        },
        {
            "id": "1025884",
            "name": "Metroid Prime: Federation Force",
            "dateRelease": "2016-09-02T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "shooter"
            ],
            "gameSeries": [
                "metroid"
            ]
        },
        {
            "id": "1025970",
            "name": "Hyrule Warriors: Legends",
            "dateRelease": "2016-03-24T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "KOEI TECMO GAMES",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "adventure"
            ],
            "gameSeries": []
        },
        {
            "id": "1026071",
            "name": "Fire Emblem Fates",
            "dateRelease": "2016-05-20T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "Intelligent Systems",
            "publisher": "Nintendo",
            "categories": [
                "strategy"
            ],
            "gameSeries": [
                "fire_emblem"
            ]
        },
        {
            "id": "1026132",
            "name": "Star Fox Zero",
            "dateRelease": "2016-04-22T00:00:00Z",
            "system": "Wii U",
            "developer": "Platinum Games",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "shooter"
            ],
            "gameSeries": [
                "star_fox"
            ]
        },
        {
            "id": "1026137",
            "name": "Animal Crossing: amiibo Festival",
            "dateRelease": "2015-11-20T00:00:00Z",
            "system": "Wii U",
            "developer": "Nintendo",
            "publisher": "Nintendo",
            "categories": [
                "animal_life",
                "board_game",
                "party"
            ],
            "gameSeries": [
                "animal_crossing"
            ]
        },
        {
            "id": "1026139",
            "name": "Mario Tennis: Ultra Smash",
            "dateRelease": "2015-11-20T00:00:00Z",
            "system": "Wii U",
            "developer": "Camelot",
            "publisher": "Nintendo",
            "categories": [
                "sports",
                "tennis"
            ],
            "gameSeries": [
                "mario_sports"
            ]
        },
        {
            "id": "1026141",
            "name": "Skylanders SuperChargers",
            "dateRelease": "2015-09-25T00:00:00Z",
            "system": "Wii U",
            "developer": "Vicarious Visions",
            "publisher": "Activision",
            "categories": [
                "action",
                "adventure"
            ],
            "gameSeries": []
        },
        {
            "id": "1026143",
            "name": "Mario & Luigi: Paper Jam Bros.",
            "dateRelease": "2015-12-04T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "AlphaDream",
            "publisher": "Nintendo",
            "categories": [
                "adventure",
                "rpg"
            ],
            "gameSeries": []
        },
        {
            "id": "1026451",
            "name": "Mario & Sonic at the Rio 2016 Olympic Games",
            "dateRelease": "2016-06-24T00:00:00Z",
            "system": "Wii U",
            "developer": "SEGA",
            "publisher": "Nintendo",
            "categories": [
                "party",
                "sports"
            ],
            "gameSeries": [
                "mario_sports",
                "sonic_the_hedgehog"
            ]
        },
        {
            "id": "1026505",
            "name": "Mario & Sonic at the Rio 2016 Olympic Games",
            "dateRelease": "2016-04-08T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "SEGA",
            "publisher": "Nintendo",
            "categories": [
                "party",
                "sports"
            ],
            "gameSeries": [
                "mario_sports",
                "sonic_the_hedgehog"
            ]
        },
        {
            "id": "1054663",
            "name": "Nintendo presents: New Style Boutique 2 - Fashion Forward",
            "dateRelease": "2015-11-20T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "syn Sophia",
            "publisher": "Nintendo",
            "categories": [
                "lifestyle",
                "simulation"
            ],
            "gameSeries": [
                "style_boutique"
            ]
        },
        {
            "id": "1062553",
            "name": "Mega Man Legacy Collection",
            "dateRelease": "2016-02-23T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "Digital Eclipse",
            "publisher": "CAPCOM",
            "categories": [
                "action",
                "arcade",
                "platformer"
            ],
            "gameSeries": []
        },
        {
            "id": "1081362",
            "name": "Word Puzzles by POWGI",
            "dateRelease": "2016-01-28T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Lightwood Games",
            "categories": [
                "education",
                "puzzle"
            ],
            "gameSeries": []
        },
        {
            "id": "1081363",
            "name": "Word Puzzles by POWGI",
            "dateRelease": "2016-01-28T00:00:00Z",
            "system": "Wii U",
            "developer": "",
            "publisher": "Lightwood Games",
            "categories": [
                "education",
                "puzzle"
            ],
            "gameSeries": []
        },
        {
            "id": "1082222",
            "name": "The Legend of Zelda: Twilight Princess HD",
            "dateRelease": "2016-03-04T00:00:00Z",
            "system": "Wii U",
            "developer": "Nintendo",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "adventure"
            ],
            "gameSeries": [
                "the_legend_of_zelda"
            ]
        },
        {
            "id": "1086374",
            "name": "Pokkén Tournament",
            "dateRelease": "2016-03-18T00:00:00Z",
            "system": "Wii U",
            "developer": "BANDAI NAMCO Entertainment",
            "publisher": "The Pokémon Company",
            "categories": [
                "arcade",
                "fighting"
            ],
            "gameSeries": [
                "pokemon"
            ]
        },
        {
            "id": "1091342",
            "name": "Kirby: Planet Robobot",
            "dateRelease": "2016-06-10T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "HAL Laboratory, Inc",
            "publisher": "Nintendo",
            "categories": [
                "platformer"
            ],
            "gameSeries": [
                "kirby"
            ]
        },
        {
            "id": "1091572",
            "name": "Star Fox Guard",
            "dateRelease": "2016-04-22T00:00:00Z",
            "system": "Wii U",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "puzzle",
                "strategy"
            ],
            "gameSeries": [
                "star_fox"
            ]
        },
        {
            "id": "1101292",
            "name": "Mini Mario & Friends: amiibo Challenge",
            "dateRelease": "2016-04-28T00:00:00Z",
            "system": "Wii U",
            "developer": "Nintendo",
            "publisher": "Nintendo",
            "categories": [
                "puzzle"
            ],
            "gameSeries": []
        },
        {
            "id": "1101346",
            "name": "Mini Mario & Friends: amiibo Challenge",
            "dateRelease": "2016-04-28T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "Nintendo",
            "publisher": "Nintendo",
            "categories": [
                "puzzle"
            ],
            "gameSeries": []
        },
        {
            "id": "1109483",
            "name": "Teddy Together",
            "dateRelease": "2016-07-01T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "Arika",
            "publisher": "Nintendo",
            "categories": [
                "animal_life",
                "simulation"
            ],
            "gameSeries": []
        },
        {
            "id": "1114009",
            "name": "Mario Party: Star Rush",
            "dateRelease": "2016-10-07T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "party"
            ],
            "gameSeries": [
                "mario_party"
            ]
        },
        {
            "id": "1125616",
            "name": "Metroid Prime: Blast Ball",
            "dateRelease": "2016-07-21T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "first_person_shooter",
                "sports"
            ],
            "gameSeries": [
                "metroid"
            ]
        },
        {
            "id": "1132221",
            "name": "Azure Striker GUNVOLT 2",
            "dateRelease": "2016-09-29T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "Inti Creates",
            "publisher": "",
            "categories": [
                "action",
                "platformer"
            ],
            "gameSeries": []
        },
        {
            "id": "1136168",
            "name": "Picross 3D: Round 2",
            "dateRelease": "2016-12-02T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "puzzle"
            ],
            "gameSeries": []
        },
        {
            "id": "1136169",
            "name": "Hey! PIKMIN",
            "dateRelease": "2017-07-28T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "Arzest",
            "publisher": "Nintendo",
            "categories": [
                "adventure",
                "platformer"
            ],
            "gameSeries": [
                "pikmin"
            ]
        },
        {
            "id": "1136171",
            "name": "Poochy & Yoshi's Woolly World",
            "dateRelease": "2017-02-03T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "Good-Feel",
            "publisher": "Nintendo",
            "categories": [
                "platformer"
            ],
            "gameSeries": [
                "yoshi"
            ]
        },
        {
            "id": "1136172",
            "name": "Mario Sports Superstars",
            "dateRelease": "2017-03-10T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "sports"
            ],
            "gameSeries": [
                "mario_sports"
            ]
        },
        {
            "id": "1173281",
            "name": "Mario Kart 8 Deluxe",
            "dateRelease": "2017-04-28T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "party",
                "racing"
            ],
            "gameSeries": [
                "mario_kart"
            ]
        },
        {
            "id": "1173295",
            "name": "Splatoon 2",
            "dateRelease": "2017-07-21T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "shooter"
            ],
            "gameSeries": [
                "splatoon"
            ]
        },
        {
            "id": "1173332",
            "name": "Super Mario Odyssey",
            "dateRelease": "2017-10-27T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "adventure",
                "platformer"
            ],
            "gameSeries": [
                "super_mario"
            ]
        },
        {
            "id": "1173609",
            "name": "The Legend of Zelda: Breath of the Wild",
            "dateRelease": "2017-03-03T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "Nintendo",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "adventure"
            ],
            "gameSeries": [
                "the_legend_of_zelda"
            ]
        },
        {
            "id": "1175211",
            "name": "Fire Emblem Echoes: Shadows of Valentia",
            "dateRelease": "2017-05-19T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "Intelligent Systems",
            "publisher": "Nintendo",
            "categories": [
                "adventure",
                "strategy"
            ],
            "gameSeries": [
                "fire_emblem"
            ]
        },
        {
            "id": "1175315",
            "name": "Fire Emblem Warriors",
            "dateRelease": "2017-10-20T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "KOEI TECMO GAMES",
            "publisher": "Nintendo",
            "categories": [
                "action"
            ],
            "gameSeries": [
                "fire_emblem"
            ]
        },
        {
            "id": "1175329",
            "name": "Fire Emblem Warriors",
            "dateRelease": "2017-10-20T00:00:00Z",
            "system": "New Nintendo 3DS",
            "developer": "KOEI TECMO GAMES",
            "publisher": "Nintendo",
            "categories": [
                "action"
            ],
            "gameSeries": [
                "fire_emblem"
            ]
        },
        {
            "id": "1177455",
            "name": "The Elder Scrolls V: Skyrim",
            "dateRelease": "2017-11-17T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "",
            "publisher": "Bethesda Softworks",
            "categories": [
                "action",
                "rpg"
            ],
            "gameSeries": []
        },
        {
            "id": "1200313",
            "name": "Shovel Knight: Treasure Trove",
            "dateRelease": "2017-03-03T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "",
            "publisher": "Yacht Club Games",
            "categories": [
                "action",
                "adventure",
                "platformer"
            ],
            "gameSeries": []
        },
        {
            "id": "1203712",
            "name": "BYE-BYE BOXBOY!",
            "dateRelease": "2017-03-23T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "HAL Laboratory, Inc.",
            "publisher": "Nintendo",
            "categories": [
                "platformer",
                "puzzle"
            ],
            "gameSeries": []
        },
        {
            "id": "1213275",
            "name": "Team Kirby Clash Deluxe",
            "dateRelease": "2017-04-13T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "HAL Laboratory, Inc.",
            "publisher": "Nintendo",
            "categories": [
                "action"
            ],
            "gameSeries": [
                "kirby"
            ]
        },
        {
            "id": "1214727",
            "name": "Kirby's Blowout Blast",
            "dateRelease": "2017-07-06T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "HAL Laboratory, Inc.",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "platformer"
            ],
            "gameSeries": [
                "kirby"
            ]
        },
        {
            "id": "1225809",
            "name": "Miitopia",
            "dateRelease": "2017-07-28T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "adventure",
                "simulation"
            ],
            "gameSeries": []
        },
        {
            "id": "1231536",
            "name": "Pokkén Tournament DX",
            "dateRelease": "2017-09-22T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "BANDAI NAMCO Entertainment",
            "publisher": "Nintendo",
            "categories": [
                "fighting"
            ],
            "gameSeries": [
                "pokemon"
            ]
        },
        {
            "id": "1233954",
            "name": "Mario + Rabbids Kingdom Battle",
            "dateRelease": "2017-08-29T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "Ubisoft Paris / Ubisoft Milan / Ubisoft Montpellier / Ubisoft Bucharest",
            "publisher": "Ubisoft",
            "categories": [
                "adventure",
                "strategy"
            ],
            "gameSeries": [
                "super_mario"
            ]
        },
        {
            "id": "1233956",
            "name": "Kirby Star Allies",
            "dateRelease": "2018-03-16T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "HAL Laboratory, Inc",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "adventure",
                "platformer"
            ],
            "gameSeries": [
                "kirby"
            ]
        },
        {
            "id": "1234232",
            "name": "Metroid: Samus Returns",
            "dateRelease": "2017-09-15T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "MercurySteam Entertainment / Nintendo",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "adventure",
                "platformer"
            ],
            "gameSeries": [
                "metroid"
            ]
        },
        {
            "id": "1234324",
            "name": "Mario & Luigi: Superstar Saga + Bowser's Minions",
            "dateRelease": "2017-10-06T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "ALPHADREAM",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "adventure",
                "platformer"
            ],
            "gameSeries": [
                "mario_and_luigi"
            ]
        },
        {
            "id": "1260979",
            "name": "Azure Striker GUNVOLT: STRIKER PACK",
            "dateRelease": "2017-12-01T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "",
            "publisher": "Inti Creates",
            "categories": [
                "action"
            ],
            "gameSeries": []
        },
        {
            "id": "1264661",
            "name": "Kirby Battle Royale",
            "dateRelease": "2017-11-03T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "HAL Laboratory, Inc.",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "fighting"
            ],
            "gameSeries": [
                "kirby"
            ]
        },
        {
            "id": "1275927",
            "name": "Mario Party: The Top 100",
            "dateRelease": "2017-12-22T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "board_game",
                "party"
            ],
            "gameSeries": [
                "mario_party"
            ]
        },
        {
            "id": "1275928",
            "name": "Nintendo presents: New Style Boutique 3 – Styling Star",
            "dateRelease": "2017-11-24T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "lifestyle",
                "simulation"
            ],
            "gameSeries": [
                "style_boutique"
            ]
        },
        {
            "id": "1313750",
            "name": "Bayonetta 2",
            "dateRelease": "2018-02-16T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "PlatinumGames",
            "publisher": "Nintendo",
            "categories": [
                "action"
            ],
            "gameSeries": [
                "bayonetta"
            ]
        },
        {
            "id": "1325790",
            "name": "Dark Souls: Remastered",
            "dateRelease": "2018-10-19T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "FromSoftware",
            "publisher": "BANDAI NAMCO Entertainment Europe S.A.S.",
            "categories": [
                "action",
                "rpg"
            ],
            "gameSeries": []
        },
        {
            "id": "1325999",
            "name": "Hyrule Warriors: Definitive Edition",
            "dateRelease": "2018-05-18T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "KOEI TECMO GAMES CO., LTD.",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "adventure"
            ],
            "gameSeries": [
                "the_legend_of_zelda"
            ]
        },
        {
            "id": "1329566",
            "name": "Detective Pikachu",
            "dateRelease": "2018-03-23T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "The Pokémon Company",
            "publisher": "Nintendo",
            "categories": [
                "adventure"
            ],
            "gameSeries": [
                "pokemon"
            ]
        },
        {
            "id": "1347594",
            "name": "Little Nightmares Complete Edition",
            "dateRelease": "2018-05-18T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "",
            "publisher": "BANDAI NAMCO Entertainment",
            "categories": [
                "action",
                "adventure",
                "puzzle"
            ],
            "gameSeries": []
        },
        {
            "id": "1348071",
            "name": "Captain Toad: Treasure Tracker",
            "dateRelease": "2018-07-13T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "platformer",
                "puzzle"
            ],
            "gameSeries": []
        },
        {
            "id": "1348072",
            "name": "Captain Toad: Treasure Tracker",
            "dateRelease": "2018-07-13T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "puzzle"
            ],
            "gameSeries": []
        },
        {
            "id": "1348073",
            "name": "WarioWare Gold",
            "dateRelease": "2018-07-27T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "Nintendo / INTELLIGENT SYSTEMS",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "party"
            ],
            "gameSeries": [
                "warioware"
            ]
        },
        {
            "id": "1348382",
            "name": "Luigi's Mansion",
            "dateRelease": "2018-10-19T00:00:00Z",
            "system": "Nintendo 3DS",
            "developer": "",
            "publisher": "Nintendo",
            "categories": [
                "action",
                "adventure"
            ],
            "gameSeries": []
        },
        {
            "id": "1375284",
            "name": "Mega Man Legacy Collection",
            "dateRelease": "2018-05-22T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "",
            "publisher": "CAPCOM Europe",
            "categories": [
                "action",
                "platformer"
            ],
            "gameSeries": []
        },
        {
            "id": "1375285",
            "name": "Mega Man Legacy Collection 2",
            "dateRelease": "2018-05-22T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "",
            "publisher": "CAPCOM",
            "categories": [
                "action",
                "platformer"
            ],
            "gameSeries": []
        },
        {
            "id": "1388641",
            "name": "Super Mario Party",
            "dateRelease": "2018-10-05T00:00:00Z",
            "system": "Nintendo Switch",
            "developer": "Nintendo",
            "publisher": "Nintendo",
            "categories": [
                "board_game",
                "party"
            ],
            "gameSeries": [
                "mario_party"
            ]
        }
    ],
    "amiibos": [
        {
            "id": "932503",
            "number": "AAAA",
            "name": "Mario",
            "dateRelease": "2014-11-28T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1026451",
                    "rank": "4",
                    "description": "You can enter the Mario League with your Mii!"
                },
                {
                    "id": "1026505",
                    "rank": "4",
                    "description": "You can power up the Mario Suit for 24 hours!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1348382",
                    "description": "You can use amiibo to give Luigi a helping hand on his adventure."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1388641",
                    "description": "You can unlock Shiny Stickers."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                }
            ]
        },
        {
            "id": "932505",
            "number": "AAAB",
            "name": "Peach",
            "dateRelease": "2014-11-28T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1388641",
                    "description": "You can unlock Shiny Stickers."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                }
            ]
        },
        {
            "id": "932510",
            "number": "AAAC",
            "name": "Yoshi",
            "dateRelease": "2014-11-28T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "892537",
                    "rank": "3",
                    "description": "You can play with Double Yoshi in single-player mode."
                },
                {
                    "id": "1136171",
                    "rank": "3",
                    "description": "You can play with Double Yoshi."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1388641",
                    "description": "You can unlock Shiny Stickers."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                }
            ]
        },
        {
            "id": "932499",
            "number": "AAAD",
            "name": "Donkey Kong",
            "dateRelease": "2014-11-28T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart",
                "super_smash_bros",
                "donkey_kong"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1388641",
                    "description": "You can unlock Shiny Stickers."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                }
            ]
        },
        {
            "id": "932502",
            "number": "AAAE",
            "name": "Link",
            "dateRelease": "2014-11-28T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros",
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "893260",
                    "rank": "2",
                    "description": "You can get a selection of items to help you on your adventure and maybe even summon the trusty steed Epona!"
                },
                {
                    "id": "1173609",
                    "rank": "2",
                    "description": "You can get a selection of items to help you on your adventure and maybe even summon the trusty steed Epona!"
                },
                {
                    "id": "1082222",
                    "rank": "4",
                    "description": "You can instantly refill your quiver with arrows."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "891849",
                    "rank": "3",
                    "description": "You can unlock the Spinner, a new weapon for Link from The Legend of Zelda: Twilight Princess!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                }
            ]
        },
        {
            "id": "932500",
            "number": "AAAF",
            "name": "Fox",
            "dateRelease": "2014-11-28T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "star_fox",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "1026132",
                    "rank": "2",
                    "description": "You can play with the Arwing from the Star Fox game on Super Nintendo Entertainment System!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1091572",
                    "rank": "1",
                    "description": "You can call in an air strike to destroy attacking robots!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                }
            ]
        },
        {
            "id": "932507",
            "number": "AAAG",
            "name": "Samus",
            "dateRelease": "2014-11-28T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "metroid",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1234232",
                    "rank": "1",
                    "description": "You can unlock an item to help you on your mission. After completing the game, you can tap this amiibo to unlock new content for the Gallery."
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1388641",
                    "rank": "2",
                    "description": "You can receive Party Points."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                }
            ]
        },
        {
            "id": "932509",
            "number": "AAAH",
            "name": "Wii Fit Trainer",
            "dateRelease": "2014-11-28T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "other",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1388641",
                    "rank": "2",
                    "description": "You can receive Party Points."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                }
            ]
        },
        {
            "id": "932508",
            "number": "AAAJ",
            "name": "Villager",
            "dateRelease": "2014-11-28T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "animal_crossing",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "1026137",
                    "rank": "1",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1009406",
                    "rank": "1",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "273841",
                    "rank": "1",
                    "description": "You can get Nintendo-themed items at the campground."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                }
            ]
        },
        {
            "id": "932506",
            "number": "AAAK",
            "name": "Pikachu",
            "dateRelease": "2014-11-28T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros",
                "pokemon"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "932501",
            "number": "AAAL",
            "name": "Kirby",
            "dateRelease": "2014-11-28T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "kirby",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1264661",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1214727",
                    "rank": "1",
                    "description": "You can unlock a musical statue in the plaza!"
                },
                {
                    "id": "1233956",
                    "rank": "2",
                    "description": "You can unlock two Illustration Pieces and additional items."
                },
                {
                    "id": "893013",
                    "rank": "1",
                    "description": "You can give Kirby extra powers!"
                },
                {
                    "id": "1091342",
                    "rank": "4",
                    "description": "You can get a special Copy Ability for Kirby!"
                },
                {
                    "id": "1213275",
                    "rank": "2",
                    "description": "You can receive a larger selection of fragments."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1203712",
                    "description": "You can unlock fresh new looks for Qbby!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                }
            ]
        },
        {
            "id": "932504",
            "number": "AAAM",
            "name": "Marth",
            "dateRelease": "2014-11-28T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "fire_emblem",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175315",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1175329",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1175211",
                    "rank": "2",
                    "description": "You can summon an Illusory Hero based on that character."
                },
                {
                    "id": "1026071",
                    "rank": "1",
                    "description": "You can receive special items and challenge the character to battle. The character may even join your army!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893949",
                    "rank": "1",
                    "description": "You can add a character to your team, and use their unique skills and weapons in battle."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "933972",
            "number": "AAAN",
            "name": "Luigi",
            "dateRelease": "2014-12-19T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1348382",
                    "description": "You can use amiibo to give Luigi a helping hand on his adventure."
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1388641",
                    "description": "You can unlock Shiny Stickers."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "933970",
            "number": "AAAP",
            "name": "Diddy Kong",
            "dateRelease": "2014-12-19T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_mario",
                "super_smash_bros",
                "donkey_kong"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1388641",
                    "description": "You can unlock Shiny Stickers."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "933974",
            "number": "AAAQ",
            "name": "Zelda",
            "dateRelease": "2014-12-19T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros",
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1082222",
                    "rank": "3",
                    "description": "You can instantly replenish your hearts."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "891849",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "933971",
            "number": "AAAR",
            "name": "Little Mac",
            "dateRelease": "2014-12-19T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "other",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "933973",
            "number": "AAAS",
            "name": "Pit",
            "dateRelease": "2014-12-19T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "kid_icarus",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "933969",
            "number": "AAAU",
            "name": "Captain Falcon",
            "dateRelease": "2014-12-19T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "other",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1388641",
                    "rank": "2",
                    "description": "You can receive Party Points."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                }
            ]
        },
        {
            "id": "935147",
            "number": "AAAT",
            "name": "Lucario",
            "dateRelease": "2015-01-23T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros",
                "pokemon"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "935148",
            "number": "AAAV",
            "name": "Rosalina",
            "dateRelease": "2015-01-23T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1388641",
                    "description": "You can unlock Shiny Stickers."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                }
            ]
        },
        {
            "id": "935145",
            "number": "AAAW",
            "name": "Bowser",
            "dateRelease": "2015-01-23T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1313750",
                    "rank": "2",
                    "description": "You can unlock a special Chain Chomp weapon!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1388641",
                    "description": "You can unlock Shiny Stickers."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1114009",
                    "rank": "1",
                    "description": "You can unlock a stamp in the Character Museum."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                }
            ]
        },
        {
            "id": "935150",
            "number": "AAAY",
            "name": "Toon Link",
            "dateRelease": "2015-01-23T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros",
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1082222",
                    "rank": "4",
                    "description": "You can instantly refill your quiver with arrows."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "891849",
                    "rank": "3",
                    "description": "You can unlock the Spinner, a new weapon for Link from The Legend of Zelda: Twilight Princess!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                }
            ]
        },
        {
            "id": "935149",
            "number": "AAAZ",
            "name": "Sheik",
            "dateRelease": "2015-01-23T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros",
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1082222",
                    "rank": "3",
                    "description": "You can instantly replenish your hearts."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "891849",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "935146",
            "number": "AABA",
            "name": "Ike",
            "dateRelease": "2015-01-23T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "fire_emblem",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175315",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1175329",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1175211",
                    "rank": "2",
                    "description": "You can summon an Illusory Hero based on that character."
                },
                {
                    "id": "1026071",
                    "rank": "1",
                    "description": "You can receive special items and challenge the character to battle. The character may even join your army!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893949",
                    "rank": "1",
                    "description": "You can add a character to your team, and use their unique skills and weapons in battle."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "935153",
            "number": "AABR",
            "name": "Meta Knight",
            "dateRelease": "2015-02-20T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "kirby",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1264661",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893013",
                    "rank": "1",
                    "description": "You can give Kirby extra powers!"
                },
                {
                    "id": "1213275",
                    "rank": "2",
                    "description": "You can receive a larger selection of fragments."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1233956",
                    "rank": "2",
                    "description": "You can unlock two Illustration Pieces and additional items."
                },
                {
                    "id": "1214727",
                    "rank": "1",
                    "description": "You can unlock a musical statue in the plaza!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1203712",
                    "description": "You can unlock fresh new looks for Qbby!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                }
            ]
        },
        {
            "id": "935151",
            "number": "AABS",
            "name": "King Dedede",
            "dateRelease": "2015-02-20T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "kirby",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1264661",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893013",
                    "rank": "1",
                    "description": "You can give Kirby extra powers!"
                },
                {
                    "id": "1213275",
                    "rank": "2",
                    "description": "You can receive a larger selection of fragments."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1233956",
                    "rank": "2",
                    "description": "You can unlock two Illustration Pieces and additional items."
                },
                {
                    "id": "1214727",
                    "rank": "1",
                    "description": "You can unlock a musical statue in the plaza!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1203712",
                    "description": "You can unlock fresh new looks for Qbby!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                }
            ]
        },
        {
            "id": "935154",
            "number": "AABV",
            "name": "Shulk",
            "dateRelease": "2015-02-20T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "other",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "961136",
                    "rank": "1",
                    "description": "You can earn in-game tokens to unlock music and character models."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "935155",
            "number": "AACA",
            "name": "Sonic",
            "dateRelease": "2015-02-20T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "sonic_the_hedgehog",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "1026451",
                    "rank": "3",
                    "description": "You can enter the Sonic League with your Mii!"
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1026505",
                    "rank": "3",
                    "description": "You can power up the Sonic Suit for 24 hours!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                }
            ]
        },
        {
            "id": "935152",
            "number": "AACB",
            "name": "Mega Man",
            "dateRelease": "2015-02-20T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "other",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1062553",
                    "rank": "1",
                    "description": "You can unlock extra challenges!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1375284",
                    "rank": "1",
                    "description": "You can unlock extra challenges!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1375285",
                    "rank": "1",
                    "description": "You can unlock extra challenges!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                }
            ]
        },
        {
            "id": "951724",
            "number": "ABAA",
            "name": "Mario",
            "dateRelease": "2015-03-20T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1026505",
                    "rank": "4",
                    "description": "You can power up the Mario Suit for 24 hours!"
                },
                {
                    "id": "1026451",
                    "rank": "4",
                    "description": "You can enter the Mario League with your Mii!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1348382",
                    "description": "You can use amiibo to give Luigi a helping hand on his adventure."
                },
                {
                    "id": "1234324",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1388641",
                    "description": "You can unlock Shiny Stickers."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                }
            ]
        },
        {
            "id": "951723",
            "number": "ABAB",
            "name": "Luigi",
            "dateRelease": "2015-03-20T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1234324",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348382",
                    "description": "You can use amiibo to give Luigi a helping hand on his adventure."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1388641",
                    "description": "You can unlock Shiny Stickers."
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "951725",
            "number": "ABAC",
            "name": "Peach",
            "dateRelease": "2015-03-20T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1234324",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                }
            ]
        },
        {
            "id": "951727",
            "number": "ABAD",
            "name": "Yoshi",
            "dateRelease": "2015-03-20T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "892537",
                    "rank": "3",
                    "description": "You can play with Double Yoshi in single-player mode."
                },
                {
                    "id": "1136171",
                    "rank": "3",
                    "description": "You can play with Double Yoshi."
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1234324",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                }
            ]
        },
        {
            "id": "951722",
            "number": "ABAF",
            "name": "Bowser",
            "dateRelease": "2015-03-20T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1091342",
                    "rank": "4",
                    "description": "You can get a special Copy Ability for Kirby!"
                },
                {
                    "id": "1313750",
                    "rank": "2",
                    "description": "You can unlock a special Chain Chomp weapon!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1114009",
                    "rank": "1",
                    "description": "You can unlock a stamp in the Character Museum."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1388641",
                    "description": "You can unlock Shiny Stickers."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1234324",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                }
            ]
        },
        {
            "id": "951726",
            "number": "ABAF",
            "name": "Toad",
            "dateRelease": "2015-03-20T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart",
                "other"
            ],
            "compatibleGames": [
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348071",
                    "rank": "2",
                    "description": "You can use this amiibo to receive an Invincibility Mushroom!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1348072",
                    "rank": "2",
                    "description": "You can use this amiibo to receive an Invincibility Mushroom!"
                },
                {
                    "id": "892923",
                    "rank": "2",
                    "description": "You can unlock a special hide-and-seek-style challenge in each stage."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348382",
                    "description": "You can use amiibo to give Luigi a helping hand on his adventure."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1234324",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "951484",
            "number": "AABC",
            "name": "Wario",
            "dateRelease": "2015-04-24T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_mario",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                }
            ]
        },
        {
            "id": "951479",
            "number": "AABN",
            "name": "Charizard",
            "dateRelease": "2015-04-24T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros",
                "pokemon"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "951480",
            "number": "AABT",
            "name": "Lucina",
            "dateRelease": "2015-04-24T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "fire_emblem"
            ],
            "compatibleGames": [
                {
                    "id": "1175211",
                    "rank": "2",
                    "description": "You can summon an Illusory Hero based on that character."
                },
                {
                    "id": "1175315",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1175329",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1026071",
                    "rank": "1",
                    "description": "You can receive special items and challenge the character to battle. The character may even join your army!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "893949",
                    "rank": "1",
                    "description": "You can add a character to your team, and use their unique skills and weapons in battle."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                }
            ]
        },
        {
            "id": "951483",
            "number": "AABU",
            "name": "Robin",
            "dateRelease": "2015-04-24T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "fire_emblem",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175315",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1175329",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1175211",
                    "rank": "2",
                    "description": "You can summon an Illusory Hero based on that character."
                },
                {
                    "id": "1026071",
                    "rank": "1",
                    "description": "You can receive special items and challenge the character to battle. The character may even join your army!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893949",
                    "rank": "1",
                    "description": "You can add a character to your team, and use their unique skills and weapons in battle."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "951481",
            "number": "AABW",
            "name": "Ness",
            "dateRelease": "2015-04-24T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "other",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "951482",
            "number": "AACC",
            "name": "PAC-MAN",
            "dateRelease": "2015-04-24T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "other",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1347594",
                    "description": "You can unlock the Pakku Mask!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                }
            ]
        },
        {
            "id": "980854",
            "number": "AABP",
            "name": "Greninja",
            "dateRelease": "2015-05-29T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros",
                "pokemon"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "980857",
            "number": "AABQ",
            "name": "Jigglypuff",
            "dateRelease": "2015-05-29T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros",
                "pokemon"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "980984",
            "number": "AEAA",
            "name": "Inkling Girl",
            "dateRelease": "2015-05-29T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "1173295",
                    "rank": "2",
                    "description": "You can use this amiibo to make the figure's character appear in-game and befriend you. You can save your gear and settings to your amiibo and they might even give you a special item."
                },
                {
                    "id": "892510",
                    "rank": "2",
                    "description": "You can unlock new missions, then beat them to acquire special weapons and gear."
                },
                {
                    "id": "273841",
                    "rank": "3",
                    "description": "You can invite Splatoon characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "1105069",
            "number": "AEAB",
            "name": "Inkling Boy",
            "dateRelease": "2015-05-29T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "1173295",
                    "rank": "2",
                    "description": "You can use this amiibo to make the figure's character appear in-game and befriend you. You can save your gear and settings to your amiibo and they might even give you a special item."
                },
                {
                    "id": "892510",
                    "rank": "2",
                    "description": "You can unlock new missions, then beat them to acquire special weapons and gear."
                },
                {
                    "id": "273841",
                    "rank": "3",
                    "description": "You can invite Splatoon characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "980985",
            "number": "AEAC",
            "name": "Inkling Squid",
            "dateRelease": "2015-05-29T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "1173295",
                    "rank": "2",
                    "description": "You can use this amiibo to make the figure's character appear in-game and befriend you. You can save your gear and settings to your amiibo and they might even give you a special item."
                },
                {
                    "id": "892510",
                    "rank": "2",
                    "description": "You can unlock new missions, then beat them to acquire special weapons and gear."
                },
                {
                    "id": "273841",
                    "rank": "3",
                    "description": "You can invite Splatoon characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "980853",
            "number": "AABD",
            "name": "Ganondorf",
            "dateRelease": "2015-06-26T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros",
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1082222",
                    "rank": "2",
                    "description": "You can increase the power of enemy attacks, making them inflict twice as much damage as normal."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "891849",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                }
            ]
        },
        {
            "id": "980850",
            "number": "AABF",
            "name": "Zero Suit Samus",
            "dateRelease": "2015-06-26T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "metroid",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1234232",
                    "rank": "1",
                    "description": "You can unlock an item to help you on your mission. After completing the game, you can tap this amiibo to unlock new content for the Gallery."
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1388641",
                    "rank": "2",
                    "description": "You can receive Party Points."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                }
            ]
        },
        {
            "id": "980855",
            "number": "AABH",
            "name": "Palutena",
            "dateRelease": "2015-06-26T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "kid_icarus",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "980852",
            "number": "AABJ",
            "name": "Dark Pit",
            "dateRelease": "2015-06-26T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "kid_icarus",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "980847",
            "number": "YAAA",
            "name": "Green Yarn Yoshi",
            "dateRelease": "2015-06-26T00:00:00Z",
            "collection": "Yoshi's Woolly World Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "892537",
                    "rank": "4",
                    "description": "You can save your favourite Yoshi pattern, then use them to play with Double Yoshi in single-player mode."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1136171",
                    "rank": "4",
                    "description": "You can save your favourite Yoshi pattern, then use them to play with Double Yoshi."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                }
            ]
        },
        {
            "id": "980849",
            "number": "YAAB",
            "name": "Pink Yarn Yoshi",
            "dateRelease": "2015-06-26T00:00:00Z",
            "collection": "Yoshi's Woolly World Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "892537",
                    "rank": "4",
                    "description": "You can save your favourite Yoshi pattern, then use them to play with Double Yoshi in single-player mode."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1136171",
                    "rank": "4",
                    "description": "You can save your favourite Yoshi pattern, then use them to play with Double Yoshi."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                }
            ]
        },
        {
            "id": "980848",
            "number": "YAAC",
            "name": "Light-Blue Yarn Yoshi",
            "dateRelease": "2015-06-26T00:00:00Z",
            "collection": "Yoshi's Woolly World Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "892537",
                    "rank": "4",
                    "description": "You can save your favourite Yoshi pattern, then use them to play with Double Yoshi in single-player mode."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1136171",
                    "rank": "4",
                    "description": "You can save your favourite Yoshi pattern, then use them to play with Double Yoshi."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                }
            ]
        },
        {
            "id": "980851",
            "number": "AAAX",
            "name": "Bowser Jr.",
            "dateRelease": "2015-07-17T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_mario",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1313750",
                    "rank": "2",
                    "description": "You can unlock a special Chain Chomp weapon!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1114009",
                    "rank": "1",
                    "description": "You can unlock a stamp in the Character Museum."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "980858",
            "number": "AABB",
            "name": "Dr. Mario",
            "dateRelease": "2015-07-17T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_mario",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1026451",
                    "rank": "4",
                    "description": "You can enter the Mario League with your Mii!"
                },
                {
                    "id": "1026505",
                    "rank": "4",
                    "description": "You can power up the Mario Suit for 24 hours!"
                },
                {
                    "id": "1114009",
                    "rank": "2",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses! You can also get an extra track in Rhythm Recital."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348382",
                    "description": "You can use amiibo to give Luigi a helping hand on his adventure."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                }
            ]
        },
        {
            "id": "980856",
            "number": "AABG",
            "name": "Olimar",
            "dateRelease": "2015-07-17T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros",
                "pikmin"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1136169",
                    "rank": "2",
                    "description": "If you pause the game during a stage and tap the Pikmin amiibo, you can call in reinforcements to even the odds!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                }
            ]
        },
        {
            "id": "1026907",
            "number": "AFAA",
            "name": "Mario (Classic Colours)",
            "dateRelease": "2015-09-11T00:00:00Z",
            "collection": "Mario 30th Anniversary Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "3",
                    "description": "You can add a Big Mushroom power-up to the course."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1026451",
                    "rank": "4",
                    "description": "You can enter the Mario League with your Mii!"
                },
                {
                    "id": "1026505",
                    "rank": "4",
                    "description": "You can power up the Mario Suit for 24 hours!"
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1348382",
                    "description": "You can use amiibo to give Luigi a helping hand on his adventure."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "1025035",
            "number": "AABK",
            "name": "Mii Brawler",
            "dateRelease": "2015-09-25T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                }
            ]
        },
        {
            "id": "1025036",
            "number": "AABL",
            "name": "Mii Sword Fighter ",
            "dateRelease": "2015-09-25T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                }
            ]
        },
        {
            "id": "1025034",
            "number": "AABM",
            "name": "Mii Gunner",
            "dateRelease": "2015-09-25T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                }
            ]
        },
        {
            "id": "1025037",
            "number": "AABX",
            "name": "Mr. Game & Watch",
            "dateRelease": "2015-09-25T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "1025032",
            "number": "AABZ",
            "name": "Duck Hunt Duo",
            "dateRelease": "2015-09-25T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "1025038",
            "number": "AACD",
            "name": "R.O.B.",
            "dateRelease": "2015-09-25T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "1093728",
            "number": "ZZZZ",
            "name": "Hammer Slam Bowser",
            "dateRelease": "2015-09-25T00:00:00Z",
            "collection": "Skylanders SuperChargers",
            "gameSeries": [
                "mario_sports",
                "mario_party",
                "super_mario",
                "mario_and_luigi"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1114009",
                    "rank": "1",
                    "description": "You can unlock a stamp in the Character Museum."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026141",
                    "rank": "1",
                    "description": "You can play the game as this character!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1093727",
            "number": "ZZZZ",
            "name": "Turbo Charge Donkey Kong",
            "dateRelease": "2015-09-25T00:00:00Z",
            "collection": "Skylanders SuperChargers",
            "gameSeries": [
                "donkey_kong"
            ],
            "compatibleGames": [
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1026141",
                    "rank": "1",
                    "description": "You can play the game as this character!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                }
            ]
        },
        {
            "id": "1252128",
            "name": "Turbo Charge Donkey Kong Dark",
            "dateRelease": "2015-09-25T00:00:00Z",
            "collection": "Skylanders SuperChargers",
            "gameSeries": [
                "other"
            ],
            "compatibleGames": [
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026141",
                    "rank": "1",
                    "description": "You can play the game as this character!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                }
            ]
        },
        {
            "id": "1048086",
            "number": "111111",
            "name": "Animal Crossing amiibo cards series 1",
            "dateRelease": "2015-10-02T00:00:00Z",
            "collection": "Animal Crossing amiibo cards",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1026137",
                    "rank": "2",
                    "description": "You can play fun minigames with your Animal Crossing friends!"
                },
                {
                    "id": "1009406",
                    "rank": "3",
                    "description": "You can receive home design requests, invite animals to homes or town facilities you've designed, and more!"
                },
                {
                    "id": "273841",
                    "rank": "6",
                    "description": "You can invite these animals to visit your village, or receive a daily item. If the animal comes to your village, you can ask them to move in. These characters can also join you in Desert Island Escape and be used in amiibo Camera. You can receive a daily item if you tap in an amiibo card of a special character, like Isabelle or Tom Nook."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892704",
                    "rank": "1",
                    "description": "If you tap certain amiibo cards, Mario will transform into a character-themed costume after grabbing a Mystery Mushroom!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "1044050",
            "number": "AACE",
            "name": "Mewtwo",
            "dateRelease": "2015-10-23T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros",
                "pokemon"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "1026908",
            "number": "AFAB",
            "name": "Mario (Modern Colours)",
            "dateRelease": "2015-10-23T00:00:00Z",
            "collection": "Mario 30th Anniversary Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "3",
                    "description": "You can add a Big Mushroom power-up to the course."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1026451",
                    "rank": "4",
                    "description": "You can enter the Mario League with your Mii!"
                },
                {
                    "id": "1026505",
                    "rank": "4",
                    "description": "You can power up the Mario Suit for 24 hours!"
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1348382",
                    "description": "You can use amiibo to give Luigi a helping hand on his adventure."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "1027025",
            "number": "ACAA",
            "name": "Chibi-Robo",
            "dateRelease": "2015-11-06T00:00:00Z",
            "collection": "Chibi-Robo Collection",
            "gameSeries": [
                "other"
            ],
            "compatibleGames": [
                {
                    "id": "1021489",
                    "rank": "3",
                    "description": "You can transform into Super Chibi-Robo in certain stages, and level it up by saving your stage scores to unlock in-game figures."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                }
            ]
        },
        {
            "id": "1073379",
            "number": "111112",
            "name": "Animal Crossing amiibo cards series 2",
            "dateRelease": "2015-11-20T00:00:00Z",
            "collection": "Animal Crossing amiibo cards",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1026137",
                    "rank": "2",
                    "description": "You can play fun minigames with your Animal Crossing friends!"
                },
                {
                    "id": "1009406",
                    "rank": "3",
                    "description": "You can receive home design requests, invite animals to homes or town facilities you've designed, and more!"
                },
                {
                    "id": "273841",
                    "rank": "6",
                    "description": "You can invite these animals to visit your village, or receive a daily item. If the animal comes to your village, you can ask them to move in. These characters can also join you in Desert Island Escape and be used in amiibo Camera. You can receive a daily item if you tap in an amiibo card of a special character, like Isabelle or Tom Nook."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892704",
                    "rank": "1",
                    "description": "If you tap certain amiibo cards, Mario will transform into a character-themed costume after grabbing a Mystery Mushroom!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "1025033",
            "number": "AABE",
            "name": "Falco",
            "dateRelease": "2015-11-20T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "star_fox",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1026132",
                    "rank": "1",
                    "description": "You can play with the Black Arwing!"
                },
                {
                    "id": "1091572",
                    "rank": "1",
                    "description": "You can call in an air strike to destroy attacking robots!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "1026902",
            "number": "AJAA",
            "name": "Isabelle",
            "dateRelease": "2015-11-20T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                }
            ]
        },
        {
            "id": "1026903",
            "number": "AJAB",
            "name": "K.K.",
            "dateRelease": "2015-11-20T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                }
            ]
        },
        {
            "id": "1026906",
            "number": "AJAC",
            "name": "Mabel",
            "dateRelease": "2015-11-20T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1027017",
            "number": "AJAD",
            "name": "Tom Nook",
            "dateRelease": "2015-11-20T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                }
            ]
        },
        {
            "id": "1026901",
            "number": "AJAE",
            "name": "Digby",
            "dateRelease": "2015-11-20T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1026905",
            "number": "AJAF",
            "name": "Lottie",
            "dateRelease": "2015-11-20T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1026904",
            "number": "AJAG",
            "name": "Reese",
            "dateRelease": "2015-11-20T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1026900",
            "number": "AJAH",
            "name": "Cyrus",
            "dateRelease": "2015-11-20T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1045071",
            "number": "YAAD",
            "name": "Mega Yarn Yoshi",
            "dateRelease": "2015-11-27T00:00:00Z",
            "collection": "Yoshi's Woolly World Collection",
            "gameSeries": [
                "super_mario",
                "mario_kart"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "892537",
                    "rank": "4",
                    "description": "You can save your favourite Yoshi pattern, then use them to play with Double Yoshi in single-player mode."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1136171",
                    "rank": "4",
                    "description": "You can save your favourite Yoshi pattern, then use them to play with Double Yoshi."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                }
            ]
        },
        {
            "id": "1093729",
            "number": "XXXXX",
            "name": "Shovel Knight",
            "dateRelease": "2016-01-08T00:00:00Z",
            "collection": "Shovel Knight amiibo",
            "gameSeries": [
                "other"
            ],
            "compatibleGames": [
                {
                    "id": "1132221",
                    "rank": "4",
                    "description": "You can unlock a boss fight with Shovel Knight!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1200313",
                    "rank": "1",
                    "description": "You can personalise Shovel Knight and take on exclusive challenge stages!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1260979",
                    "rank": "1",
                    "description": "You can unlock a boss fight with Shovel Knight!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "858507",
                    "rank": "1",
                    "description": "You can personalise Shovel Knight and take on exclusive challenge stages!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "858506",
                    "rank": "1",
                    "description": "You can unlock a co-operative multiplayer mode, personalise Shovel Knight and take on exclusive challenge stages!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "1073381",
            "number": "AACF",
            "name": "Lucas",
            "dateRelease": "2016-01-29T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "other",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "1073377",
            "number": "AJAJ",
            "name": "Blathers",
            "dateRelease": "2016-01-29T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1073378",
            "number": "AJAK",
            "name": "Celeste ",
            "dateRelease": "2016-01-29T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1073376",
            "number": "AJAL",
            "name": "Resetti",
            "dateRelease": "2016-01-29T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                }
            ]
        },
        {
            "id": "1073380",
            "number": "AJAM",
            "name": "Kicks ",
            "dateRelease": "2016-01-29T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1073374",
            "number": "AKAA",
            "name": "Wolf Link",
            "dateRelease": "2016-03-04T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1173609",
                    "rank": "4",
                    "description": "Wolf Link can appear in-game and join you as a partner!"
                },
                {
                    "id": "1082222",
                    "rank": "5",
                    "description": "You can access the Cave of Shadows bonus challenge, and save a record of your remaining hearts after conquering the cave."
                },
                {
                    "id": "893260",
                    "rank": "4",
                    "description": "Wolf Link can appear in-game and join you as a partner!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "1085768",
            "number": "111113",
            "name": "Animal Crossing amiibo cards series 3",
            "dateRelease": "2016-03-18T00:00:00Z",
            "collection": "Animal Crossing amiibo cards",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1026137",
                    "rank": "2",
                    "description": "You can play fun minigames with your Animal Crossing friends!"
                },
                {
                    "id": "1009406",
                    "rank": "3",
                    "description": "You can receive home design requests, invite animals to homes or town facilities you've designed, and more!"
                },
                {
                    "id": "273841",
                    "rank": "6",
                    "description": "You can invite these animals to visit your village, or receive a daily item. If the animal comes to your village, you can ask them to move in. These characters can also join you in Desert Island Escape and be used in amiibo Camera. You can receive a daily item if you tap in an amiibo card of a special character, like Isabelle or Tom Nook."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892704",
                    "rank": "1",
                    "description": "If you tap certain amiibo cards, Mario will transform into a character-themed costume after grabbing a Mystery Mushroom!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "1091382",
            "number": "211115",
            "name": "Shadow Mewtwo",
            "dateRelease": "2016-03-18T00:00:00Z",
            "collection": "Other",
            "gameSeries": [
                "pokemon"
            ],
            "compatibleGames": [
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1086374",
                    "rank": "2",
                    "description": "You can unlock Shadow Mewtwo as a playable character for the current play session!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "892704",
                    "rank": "1",
                    "description": "If you tap certain amiibo cards, Mario will transform into a character-themed costume after grabbing a Mystery Mushroom!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                }
            ]
        },
        {
            "id": "1077504",
            "number": "AABY",
            "name": "R.O.B. (Famicom Colours)",
            "dateRelease": "2016-03-18T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "1077502",
            "number": "AACG",
            "name": "Roy",
            "dateRelease": "2016-03-18T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175329",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1175315",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1175211",
                    "rank": "2",
                    "description": "You can summon an Illusory Hero based on that character."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                }
            ]
        },
        {
            "id": "1077503",
            "number": "AACH",
            "name": "Ryu",
            "dateRelease": "2016-03-18T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "1081908",
            "number": "AJAN",
            "name": "Isabelle (Summer Outfit)",
            "dateRelease": "2016-03-18T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                }
            ]
        },
        {
            "id": "1081505",
            "number": "AJAP",
            "name": "Rover",
            "dateRelease": "2016-03-18T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1081506",
            "number": "AJAQ",
            "name": "Timmy & Tommy",
            "dateRelease": "2016-03-18T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1081507",
            "number": "AJAR",
            "name": "Kapp'n",
            "dateRelease": "2016-03-18T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1009406",
                    "rank": "2",
                    "description": "You can receive a home design request."
                },
                {
                    "id": "1026137",
                    "rank": "3",
                    "description": "You can use your amiibo as your board game piece!"
                },
                {
                    "id": "273841",
                    "rank": "5",
                    "description": "You can invite these special characters to visit the campground in their camper. These characters can also join you in Desert Island Escape, give you a helping hand in Animal Crossing Puzzle League and be used with the amiibo Camera."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1092892",
            "number": "ALAA",
            "name": "Kirby",
            "dateRelease": "2016-06-10T00:00:00Z",
            "collection": "Kirby Collection",
            "gameSeries": [
                "kirby"
            ],
            "compatibleGames": [
                {
                    "id": "1091342",
                    "rank": "4",
                    "description": "You can get a special Copy Ability for Kirby!"
                },
                {
                    "id": "1264661",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1214727",
                    "rank": "1",
                    "description": "You can unlock a musical statue in the plaza!"
                },
                {
                    "id": "893013",
                    "rank": "1",
                    "description": "You can give Kirby extra powers!"
                },
                {
                    "id": "1213275",
                    "rank": "2",
                    "description": "You can receive a larger selection of fragments."
                },
                {
                    "id": "1233956",
                    "rank": "2",
                    "description": "You can unlock two Illustration Pieces and additional items."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1203712",
                    "description": "You can unlock fresh new looks for Qbby!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                }
            ]
        },
        {
            "id": "1092893",
            "number": "ALAB",
            "name": "Meta Knight",
            "dateRelease": "2016-06-10T00:00:00Z",
            "collection": "Kirby Collection",
            "gameSeries": [
                "kirby"
            ],
            "compatibleGames": [
                {
                    "id": "1264661",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1214727",
                    "rank": "1",
                    "description": "You can unlock a musical statue in the plaza!"
                },
                {
                    "id": "1233956",
                    "rank": "2",
                    "description": "You can unlock two Illustration Pieces and additional items."
                },
                {
                    "id": "893013",
                    "rank": "1",
                    "description": "You can give Kirby extra powers!"
                },
                {
                    "id": "1091342",
                    "rank": "4",
                    "description": "You can get a special Copy Ability for Kirby!"
                },
                {
                    "id": "1213275",
                    "rank": "2",
                    "description": "You can receive a larger selection of fragments."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1203712",
                    "description": "You can unlock fresh new looks for Qbby!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1092894",
            "number": "ALAC",
            "name": "King Dedede",
            "dateRelease": "2016-06-10T00:00:00Z",
            "collection": "Kirby Collection",
            "gameSeries": [
                "kirby"
            ],
            "compatibleGames": [
                {
                    "id": "1264661",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1214727",
                    "rank": "1",
                    "description": "You can unlock a musical statue in the plaza!"
                },
                {
                    "id": "1233956",
                    "rank": "2",
                    "description": "You can unlock two Illustration Pieces and additional items."
                },
                {
                    "id": "893013",
                    "rank": "1",
                    "description": "You can give Kirby extra powers!"
                },
                {
                    "id": "1091342",
                    "rank": "4",
                    "description": "You can get a special Copy Ability for Kirby!"
                },
                {
                    "id": "1213275",
                    "rank": "2",
                    "description": "You can receive a larger selection of fragments."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1203712",
                    "description": "You can unlock fresh new looks for Qbby!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1092891",
            "number": "ALAD",
            "name": "Waddle Dee",
            "dateRelease": "2016-06-10T00:00:00Z",
            "collection": "Kirby Collection",
            "gameSeries": [
                "kirby"
            ],
            "compatibleGames": [
                {
                    "id": "1264661",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1214727",
                    "rank": "1",
                    "description": "You can unlock a musical statue in the plaza!"
                },
                {
                    "id": "1233956",
                    "rank": "2",
                    "description": "You can unlock two Illustration Pieces and additional items."
                },
                {
                    "id": "1091342",
                    "rank": "4",
                    "description": "You can get a special Copy Ability for Kirby!"
                },
                {
                    "id": "1213275",
                    "rank": "2",
                    "description": "You can receive a larger selection of fragments."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1203712",
                    "description": "You can unlock fresh new looks for Qbby!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "1093492",
            "number": "111114",
            "name": "Animal Crossing amiibo cards series 4",
            "dateRelease": "2016-06-17T00:00:00Z",
            "collection": "Animal Crossing amiibo cards",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "1026137",
                    "rank": "2",
                    "description": "You can play fun minigames with your Animal Crossing friends!"
                },
                {
                    "id": "1009406",
                    "rank": "3",
                    "description": "You can receive home design requests, invite animals to homes or town facilities you've designed, and more!"
                },
                {
                    "id": "273841",
                    "rank": "6",
                    "description": "You can invite these animals to visit your village, or receive a daily item. If the animal comes to your village, you can ask them to move in. These characters can also join you in Desert Island Escape and be used in amiibo Camera. You can receive a daily item if you tap in an amiibo card of a special character, like Isabelle or Tom Nook."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892704",
                    "rank": "1",
                    "description": "If you tap certain amiibo cards, Mario will transform into a character-themed costume after grabbing a Mystery Mushroom!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "1104824",
            "number": "AEAD",
            "name": "Callie",
            "dateRelease": "2016-07-08T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "892510",
                    "rank": "1",
                    "description": "You can see the Squid Sisters perform songs in-game! Each Squid Sister amiibo unlocks an exclusive solo song."
                },
                {
                    "id": "1173295",
                    "rank": "2",
                    "description": "You can use this amiibo to make the figure's character appear in-game and befriend you. You can save your gear and settings to your amiibo and they might even give you a special item."
                },
                {
                    "id": "273841",
                    "rank": "3",
                    "description": "You can invite Splatoon characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1104825",
            "number": "AEAE",
            "name": "Marie",
            "dateRelease": "2016-07-08T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "892510",
                    "rank": "1",
                    "description": "You can see the Squid Sisters perform songs in-game! Each Squid Sister amiibo unlocks an exclusive solo song."
                },
                {
                    "id": "1173295",
                    "rank": "2",
                    "description": "You can use this amiibo to make the figure's character appear in-game and befriend you. You can save your gear and settings to your amiibo and they might even give you a special item."
                },
                {
                    "id": "273841",
                    "rank": "3",
                    "description": "You can invite Splatoon characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1104822",
            "number": "AEAF",
            "name": "Inkling Girl (Lime Green)",
            "dateRelease": "2016-07-08T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "1173295",
                    "rank": "2",
                    "description": "You can use this amiibo to make the figure's character appear in-game and befriend you. You can save your gear and settings to your amiibo and they might even give you a special item."
                },
                {
                    "id": "892510",
                    "rank": "2",
                    "description": "You can unlock new missions, then beat them to acquire special weapons and gear."
                },
                {
                    "id": "273841",
                    "rank": "3",
                    "description": "You can invite Splatoon characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                }
            ]
        },
        {
            "id": "1104826",
            "number": "AEAG",
            "name": "Inkling Boy (Purple)",
            "dateRelease": "2016-07-08T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "1173295",
                    "rank": "2",
                    "description": "You can use this amiibo to make the figure's character appear in-game and befriend you. You can save your gear and settings to your amiibo and they might even give you a special item."
                },
                {
                    "id": "892510",
                    "rank": "2",
                    "description": "You can unlock new missions, then beat them to acquire special weapons and gear."
                },
                {
                    "id": "273841",
                    "rank": "3",
                    "description": "You can invite Splatoon characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1104823",
            "number": "AEAH",
            "name": "Inkling Squid (Orange)",
            "dateRelease": "2016-07-08T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "1173295",
                    "rank": "2",
                    "description": "You can use this amiibo to make the figure's character appear in-game and befriend you. You can save your gear and settings to your amiibo and they might even give you a special item."
                },
                {
                    "id": "892510",
                    "rank": "2",
                    "description": "You can unlock new missions, then beat them to acquire special weapons and gear."
                },
                {
                    "id": "273841",
                    "rank": "3",
                    "description": "You can invite Splatoon characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1125634",
            "number": "ABAJ",
            "name": "Rosalina",
            "dateRelease": "2016-10-07T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1025884",
                    "rank": "1",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1234324",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                }
            ]
        },
        {
            "id": "1125635",
            "number": "ABAK",
            "name": "Wario",
            "dateRelease": "2016-10-07T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1234324",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1125633",
            "number": "ABAL",
            "name": "Donkey Kong",
            "dateRelease": "2016-10-07T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario",
                "donkey_kong"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1234324",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                }
            ]
        },
        {
            "id": "1125632",
            "number": "ABAQ",
            "name": "Boo",
            "dateRelease": "2016-10-07T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario"
            ],
            "compatibleGames": [
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1114009",
                    "rank": "3",
                    "description": "You can make Boo appear in the background of Boo’s Block Party! You can also unlock a stamp in the Character Museum."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1234324",
                    "rank": "2",
                    "description": "You can use these amiibo to unlock additional stamp sheets"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1313750",
                    "rank": "2",
                    "description": "You can unlock a special Chain Chomp weapon!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1348382",
                    "description": "You can use amiibo to give Luigi a helping hand on his adventure."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1388641",
                    "description": "You can unlock Shiny Stickers."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                }
            ]
        },
        {
            "id": "1125636",
            "number": "ABAM",
            "name": "Diddy Kong",
            "dateRelease": "2016-11-04T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario",
                "donkey_kong"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1234324",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1125627",
            "number": "ABAN",
            "name": "Daisy",
            "dateRelease": "2016-11-04T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario"
            ],
            "compatibleGames": [
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1234324",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "1125626",
            "number": "ABAP",
            "name": "Waluigi",
            "dateRelease": "2016-11-04T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1234324",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                }
            ]
        },
        {
            "id": "1136394",
            "number": "0000",
            "name": "Animal Crossing: New Leaf amiibo cards",
            "dateRelease": "2016-11-11T00:00:00Z",
            "collection": "Animal Crossing amiibo cards",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "273841",
                    "rank": "7",
                    "description": "You can invite the characters from these cards to visit the campground in their campers, or receive a daily item. These characters can also join you in Desert Island Escape and be used with the amiibo Camera."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                }
            ]
        },
        {
            "id": "1151647",
            "number": "111115",
            "name": "Animal Crossing: New Leaf - Sanrio Collaboration Pack",
            "dateRelease": "2016-11-25T00:00:00Z",
            "collection": "Animal Crossing Collection",
            "gameSeries": [
                "animal_crossing"
            ],
            "compatibleGames": [
                {
                    "id": "273841",
                    "rank": "4",
                    "description": "You can invite the characters from these cards to visit the campground in their campers, or receive a daily item. These characters can also join you in Desert Island Escape and be used with the amiibo Camera."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173281",
                    "rank": "1",
                    "description": "You can unlock a Mii Suit!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                }
            ]
        },
        {
            "id": "1136389",
            "number": "AKAB",
            "name": "Link (Ocarina of Time)",
            "dateRelease": "2016-12-02T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1082222",
                    "rank": "4",
                    "description": "You can instantly refill your quiver with arrows."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "891849",
                    "rank": "3",
                    "description": "You can unlock the Spinner, a new weapon for Link from The Legend of Zelda: Twilight Princess!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                }
            ]
        },
        {
            "id": "1136390",
            "number": "AKAF",
            "name": "Link (The Legend of Zelda)",
            "dateRelease": "2016-12-02T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1082222",
                    "rank": "4",
                    "description": "You can instantly refill your quiver with arrows."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "891849",
                    "rank": "3",
                    "description": "You can unlock the Spinner, a new weapon for Link from The Legend of Zelda: Twilight Princess!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                }
            ]
        },
        {
            "id": "1136391",
            "number": "AKAG",
            "name": "Toon Link (The Wind Waker)",
            "dateRelease": "2016-12-02T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1082222",
                    "rank": "4",
                    "description": "You can instantly refill your quiver with arrows."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "891849",
                    "rank": "3",
                    "description": "You can unlock the Spinner, a new weapon for Link from The Legend of Zelda: Twilight Princess!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                }
            ]
        },
        {
            "id": "1136392",
            "number": "AKAJ",
            "name": "Zelda (The Wind Waker)",
            "dateRelease": "2016-12-02T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1082222",
                    "rank": "3",
                    "description": "You can instantly replenish your hearts."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "891849",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1136393",
            "number": "YAAE",
            "name": "Poochy",
            "dateRelease": "2017-02-03T00:00:00Z",
            "collection": "Yoshi's Woolly World Collection",
            "gameSeries": [
                "yoshi"
            ],
            "compatibleGames": [
                {
                    "id": "1136171",
                    "rank": "5",
                    "description": "You can tap this amiibo to enlist Poochy's help at any time!"
                },
                {
                    "id": "892537",
                    "rank": "5",
                    "description": "You can tap this amiibo to enlist Poochy's help at any time!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                }
            ]
        },
        {
            "id": "1125629",
            "number": "AKAK",
            "name": "Link (Archer) ",
            "dateRelease": "2017-03-03T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1082222",
                    "rank": "4",
                    "description": "You can instantly refill your quiver with arrows."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "891849",
                    "rank": "3",
                    "description": "You can unlock the Spinner, a new weapon for Link from The Legend of Zelda: Twilight Princess!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                }
            ]
        },
        {
            "id": "1125630",
            "number": "AKAL",
            "name": "Link (Rider)",
            "dateRelease": "2017-03-03T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1082222",
                    "rank": "4",
                    "description": "You can instantly refill your quiver with arrows."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "891849",
                    "rank": "3",
                    "description": "You can unlock the Spinner, a new weapon for Link from The Legend of Zelda: Twilight Princess!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                }
            ]
        },
        {
            "id": "1125631",
            "number": "AKAM",
            "name": "Guardian",
            "dateRelease": "2017-03-03T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                }
            ]
        },
        {
            "id": "1173403",
            "number": "AKAN",
            "name": "Zelda",
            "dateRelease": "2017-03-03T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1082222",
                    "rank": "3",
                    "description": "You can instantly replenish your hearts."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "891849",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                }
            ]
        },
        {
            "id": "1173404",
            "number": "AKAU",
            "name": "Bokoblin",
            "dateRelease": "2017-03-03T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                }
            ]
        },
        {
            "id": "1183287",
            "number": "0000",
            "name": "Mario Sports Superstars amiibo cards",
            "dateRelease": "2017-03-10T00:00:00Z",
            "collection": "Mario Sports Superstars",
            "gameSeries": [
                "mario_sports"
            ],
            "compatibleGames": [
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1136172",
                    "rank": "2",
                    "description": "You can unlock more powerful versions of characters and improve their abilities!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                }
            ]
        },
        {
            "id": "1175816",
            "number": "AQAA",
            "name": "Alm",
            "dateRelease": "2017-05-19T00:00:00Z",
            "collection": "Fire Emblem Collection",
            "gameSeries": [
                "fire_emblem"
            ],
            "compatibleGames": [
                {
                    "id": "1175211",
                    "rank": "3",
                    "description": "You can unlock an exclusive dungeon and also summon an Illusory Hero based on that character to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1175315",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                }
            ]
        },
        {
            "id": "1175817",
            "number": "AQAB",
            "name": "Celica",
            "dateRelease": "2017-05-19T00:00:00Z",
            "collection": "Fire Emblem Collection",
            "gameSeries": [
                "fire_emblem"
            ],
            "compatibleGames": [
                {
                    "id": "1175211",
                    "rank": "3",
                    "description": "You can unlock an exclusive dungeon and also summon an Illusory Hero based on that character to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1175315",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                }
            ]
        },
        {
            "id": "1222081",
            "number": "AKAC",
            "name": "Link (Majora's Mask)",
            "dateRelease": "2017-06-23T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1082222",
                    "rank": "4",
                    "description": "You can instantly refill your quiver with arrows."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "891849",
                    "rank": "3",
                    "description": "You can unlock the Spinner, a new weapon for Link from The Legend of Zelda: Twilight Princess!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                }
            ]
        },
        {
            "id": "1222082",
            "number": "AKAD",
            "name": "Link (Twilight Princess)",
            "dateRelease": "2017-06-23T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "1173609",
                    "rank": "2",
                    "description": "You can get a selection of items to help you on your adventure and maybe even summon the trusty steed Epona!"
                },
                {
                    "id": "1082222",
                    "rank": "4",
                    "description": "You can instantly refill your quiver with arrows."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "893260",
                    "rank": "2",
                    "description": "You can get a selection of items to help you on your adventure and maybe even summon the trusty steed Epona!"
                },
                {
                    "id": "891849",
                    "rank": "3",
                    "description": "You can unlock the Spinner, a new weapon for Link from The Legend of Zelda: Twilight Princess!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                }
            ]
        },
        {
            "id": "1222083",
            "number": "AKAE",
            "name": "Link (Skyward Sword)",
            "dateRelease": "2017-06-23T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "893260",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "1082222",
                    "rank": "4",
                    "description": "You can instantly refill your quiver with arrows."
                },
                {
                    "id": "1325999",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1173609",
                    "rank": "3",
                    "description": "You can get a selection of items and a chest to help you on your adventure!"
                },
                {
                    "id": "891849",
                    "rank": "3",
                    "description": "You can unlock the Spinner, a new weapon for Link from The Legend of Zelda: Twilight Princess!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "273841",
                    "rank": "2",
                    "description": "You can invite The Legend of Zelda characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1025970",
                    "rank": "2",
                    "description": "You can get a powerful weapon for the character to wield in-game."
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                }
            ]
        },
        {
            "id": "1222088",
            "number": "AACK",
            "name": "Cloud",
            "dateRelease": "2017-07-21T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "other",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "1222093",
            "number": "AACL",
            "name": "Corrin",
            "dateRelease": "2017-07-21T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "fire_emblem",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175211",
                    "rank": "2",
                    "description": "You can summon an Illusory Hero based on that character."
                },
                {
                    "id": "1175315",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1175329",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                }
            ]
        },
        {
            "id": "1222090",
            "number": "AACM",
            "name": "Bayonetta",
            "dateRelease": "2017-07-21T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "other",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                }
            ]
        },
        {
            "id": "1222089",
            "number": "AACN",
            "name": "Cloud - Player 2",
            "dateRelease": "2017-07-21T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "other",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "1222092",
            "number": "AACP",
            "name": "Corrin - Player 2",
            "dateRelease": "2017-07-21T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "fire_emblem",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175211",
                    "rank": "2",
                    "description": "You can summon an Illusory Hero based on that character."
                },
                {
                    "id": "1175315",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1175329",
                    "rank": "2",
                    "description": "You can receive a strong weapon for that character to use."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                }
            ]
        },
        {
            "id": "1222091",
            "number": "AACQ",
            "name": "Bayonetta - Player 2",
            "dateRelease": "2017-07-21T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "other",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                }
            ]
        },
        {
            "id": "1222085",
            "number": "AEAJ",
            "name": "Inkling Girl",
            "dateRelease": "2017-07-21T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "1173295",
                    "rank": "2",
                    "description": "You can use this amiibo to make the figure's character appear in-game and befriend you. You can save your gear and settings to your amiibo and they might even give you a special item."
                },
                {
                    "id": "892510",
                    "rank": "2",
                    "description": "You can unlock new missions, then beat them to acquire special weapons and gear."
                },
                {
                    "id": "273841",
                    "rank": "3",
                    "description": "You can invite Splatoon characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1222087",
            "number": "AEAK",
            "name": "Inkling Boy",
            "dateRelease": "2017-07-21T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "892510",
                    "rank": "2",
                    "description": "You can unlock new missions, then beat them to acquire special weapons and gear."
                },
                {
                    "id": "1173295",
                    "rank": "2",
                    "description": "You can use this amiibo to make the figure's character appear in-game and befriend you. You can save your gear and settings to your amiibo and they might even give you a special item."
                },
                {
                    "id": "273841",
                    "rank": "3",
                    "description": "You can invite Splatoon characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1222086",
            "number": "AEAL",
            "name": "Inkling Squid",
            "dateRelease": "2017-07-21T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "892510",
                    "rank": "2",
                    "description": "You can unlock new missions, then beat them to acquire special weapons and gear."
                },
                {
                    "id": "1173295",
                    "rank": "2",
                    "description": "You can use this amiibo to make the figure's character appear in-game and befriend you. You can save your gear and settings to your amiibo and they might even give you a special item."
                },
                {
                    "id": "273841",
                    "rank": "3",
                    "description": "You can invite Splatoon characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1222084",
            "number": "APAA",
            "name": "Pikmin",
            "dateRelease": "2017-07-28T00:00:00Z",
            "collection": "Pikmin Collection",
            "gameSeries": [
                "pikmin"
            ],
            "compatibleGames": [
                {
                    "id": "1136169",
                    "rank": "2",
                    "description": "If you pause the game during a stage and tap the Pikmin amiibo, you can call in reinforcements to even the odds!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                }
            ]
        },
        {
            "id": "1239436",
            "name": "Samus",
            "dateRelease": "2017-09-15T00:00:00Z",
            "collection": "Metroid Collection",
            "gameSeries": [
                "metroid"
            ],
            "compatibleGames": [
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1234232",
                    "rank": "1",
                    "description": "You can unlock an item to help you on your mission. After completing the game, you can tap this amiibo to unlock new content for the Gallery."
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1239435",
            "name": "Metroid",
            "dateRelease": "2017-09-15T00:00:00Z",
            "collection": "Metroid Collection",
            "gameSeries": [
                "metroid"
            ],
            "compatibleGames": [
                {
                    "id": "1234232",
                    "rank": "2",
                    "description": "You can tap this amiibo to find out if there's a Metroid nearby. After completing the game, you can tap this amiibo to unlock Fusion mode."
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                }
            ]
        },
        {
            "id": "1239442",
            "name": "Goomba",
            "dateRelease": "2017-10-06T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario",
                "mario_and_luigi"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1234324",
                    "rank": "2",
                    "description": "You can use these amiibo to unlock additional stamp sheets"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1313750",
                    "rank": "2",
                    "description": "You can unlock a special Chain Chomp weapon!"
                },
                {
                    "id": "1275927",
                    "rank": "2",
                    "description": "You can quickly unlock a new minigame pack, and get extra coins and lives once per day in Minigame Island!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                }
            ]
        },
        {
            "id": "1239443",
            "name": "Koopa Troopa",
            "dateRelease": "2017-10-06T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "other"
            ],
            "compatibleGames": [
                {
                    "id": "1234324",
                    "rank": "2",
                    "description": "You can use these amiibo to unlock additional stamp sheets"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1275927",
                    "rank": "2",
                    "description": "You can quickly unlock a new minigame pack, and get extra coins and lives once per day in Minigame Island!"
                },
                {
                    "id": "1313750",
                    "rank": "2",
                    "description": "You can unlock a special Chain Chomp weapon!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                }
            ]
        },
        {
            "id": "1239438",
            "name": "Chrom",
            "dateRelease": "2017-10-20T00:00:00Z",
            "collection": "Fire Emblem Collection",
            "gameSeries": [
                "fire_emblem"
            ],
            "compatibleGames": [
                {
                    "id": "1175315",
                    "rank": "3",
                    "description": "You can receive an exclusive weapon. You can use this amiibo again to increase your chances of receiving a high value weapon."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "3",
                    "description": "You can receive an exclusive weapon. You can use this amiibo again to increase your chances of receiving a high value weapon."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                }
            ]
        },
        {
            "id": "1239437",
            "name": "Tiki",
            "dateRelease": "2017-10-20T00:00:00Z",
            "collection": "Fire Emblem Collection",
            "gameSeries": [
                "fire_emblem"
            ],
            "compatibleGames": [
                {
                    "id": "1175315",
                    "rank": "3",
                    "description": "You can receive an exclusive weapon. You can use this amiibo again to increase your chances of receiving a high value weapon."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "3",
                    "description": "You can receive an exclusive weapon. You can use this amiibo again to increase your chances of receiving a high value weapon."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                }
            ]
        },
        {
            "id": "1239439",
            "name": "Bowser",
            "dateRelease": "2017-10-27T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario"
            ],
            "compatibleGames": [
                {
                    "id": "1173332",
                    "rank": "2",
                    "description": "You can receive a wedding outfit and reveal regional coin locations"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1348071",
                    "rank": "3",
                    "description": "You can instantly unlock the stages based on Super Mario Odyssey by scanning one of these amiibo."
                },
                {
                    "id": "1348072",
                    "rank": "3",
                    "description": "You can instantly unlock the stages based on Super Mario Odyssey by scanning one of these amiibo."
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1313750",
                    "rank": "2",
                    "description": "You can unlock a special Chain Chomp weapon!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                }
            ]
        },
        {
            "id": "1239441",
            "name": "Mario",
            "dateRelease": "2017-10-27T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1173332",
                    "rank": "4",
                    "description": "You can receive a wedding outfit and gain temporary invincibility"
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1026505",
                    "rank": "4",
                    "description": "You can power up the Mario Suit for 24 hours!"
                },
                {
                    "id": "1026451",
                    "rank": "4",
                    "description": "You can enter the Mario League with your Mii!"
                },
                {
                    "id": "1348072",
                    "rank": "3",
                    "description": "You can instantly unlock the stages based on Super Mario Odyssey by scanning one of these amiibo."
                },
                {
                    "id": "1348071",
                    "rank": "3",
                    "description": "You can instantly unlock the stages based on Super Mario Odyssey by scanning one of these amiibo."
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1348382",
                    "description": "You can use amiibo to give Luigi a helping hand on his adventure."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                }
            ]
        },
        {
            "id": "1239440",
            "name": "Peach",
            "dateRelease": "2017-10-27T00:00:00Z",
            "collection": "Super Mario Collection",
            "gameSeries": [
                "super_mario"
            ],
            "compatibleGames": [
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1233954",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "3",
                    "description": "You can receive a wedding outfit and receive a Life-Up Heart"
                },
                {
                    "id": "1114009",
                    "rank": "4",
                    "description": "Your amiibo can join you as an ally character in Toad Scramble. In other modes, you can play as the amiibo character, receive special Dice Blocks or get other bonuses!"
                },
                {
                    "id": "1313750",
                    "rank": "3",
                    "description": "You can unlock special costumes!"
                },
                {
                    "id": "1348072",
                    "rank": "3",
                    "description": "You can instantly unlock the stages based on Super Mario Odyssey by scanning one of these amiibo."
                },
                {
                    "id": "1348071",
                    "rank": "3",
                    "description": "You can instantly unlock the stages based on Super Mario Odyssey by scanning one of these amiibo."
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1054663",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "892537",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892779",
                    "rank": "2",
                    "description": "You can unlock amiibo Party mode, save Tokens to amiibo, customise boards in amiibo Party, and more!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1136171",
                    "rank": "2",
                    "description": "You can unlock a character-themed Yoshi pattern."
                },
                {
                    "id": "1101346",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1101292",
                    "rank": "2",
                    "description": "You can play as a Mini Toy and unlock levels to match the character!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1021489",
                    "rank": "2",
                    "description": "You can unlock figures or earn extra moolah in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1026139",
                    "rank": "1",
                    "description": "You can train your amiibo as a doubles partner!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "954437",
                    "rank": "1",
                    "description": "You can unlock a character-themed aircraft skin."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1026143",
                    "rank": "1",
                    "description": "You can unlock character cards to use in battle!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "864849",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "765384",
                    "rank": "1",
                    "description": "If you tap an amiibo to the Wii U GamePad, you'll unlock a Mii racing suit based on that character's look!"
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "864329",
                    "rank": "1",
                    "description": "You can bring your amiibo into the game as a powerful fighter that grows and evolves over time."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1136168",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1275927",
                    "rank": "1",
                    "description": "You can get an extra life once per day in Minigame Island!"
                }
            ]
        },
        {
            "id": "1239432",
            "name": "Mipha",
            "dateRelease": "2017-11-10T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "893260",
                    "rank": "5",
                    "description": "You can unlock materials, weapons and special headgear inspired by their Divine Beasts."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "5",
                    "description": "You can unlock materials, weapons and special headgear inspired by their Divine Beasts."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                }
            ]
        },
        {
            "id": "1239431",
            "name": "Daruk",
            "dateRelease": "2017-11-10T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "the_legend_of_zelda"
            ],
            "compatibleGames": [
                {
                    "id": "893260",
                    "rank": "5",
                    "description": "You can unlock materials, weapons and special headgear inspired by their Divine Beasts."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1173609",
                    "rank": "5",
                    "description": "You can unlock materials, weapons and special headgear inspired by their Divine Beasts."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                }
            ]
        },
        {
            "id": "1239433",
            "name": "Revali",
            "dateRelease": "2017-11-10T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "other"
            ],
            "compatibleGames": [
                {
                    "id": "1173609",
                    "rank": "5",
                    "description": "You can unlock materials, weapons and special headgear inspired by their Divine Beasts."
                },
                {
                    "id": "893260",
                    "rank": "5",
                    "description": "You can unlock materials, weapons and special headgear inspired by their Divine Beasts."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1239434",
            "name": "Urbosa",
            "dateRelease": "2017-11-10T00:00:00Z",
            "collection": "The Legend of Zelda Collection",
            "gameSeries": [
                "other"
            ],
            "compatibleGames": [
                {
                    "id": "1173609",
                    "rank": "5",
                    "description": "You can unlock materials, weapons and special headgear inspired by their Divine Beasts."
                },
                {
                    "id": "893260",
                    "rank": "5",
                    "description": "You can unlock materials, weapons and special headgear inspired by their Divine Beasts."
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1177455",
                    "rank": "1",
                    "description": "You can unlock outfits and gear inspired by The Legend of Zelda series."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        },
        {
            "id": "1335555",
            "number": "ASAA",
            "name": "Detective Pikachu",
            "dateRelease": "2018-03-23T00:00:00Z",
            "collection": "Detective Pikachu amiibo",
            "gameSeries": [
                "pokemon"
            ],
            "compatibleGames": [
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1329566",
                    "description": "You can unlock all Pika Prompts for any chapters you've cleared."
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                }
            ]
        },
        {
            "id": "1368234",
            "number": "ZZZZZZ",
            "name": "Marina",
            "dateRelease": "2018-07-13T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "1173295",
                    "rank": "1",
                    "description": "You can unlock new gear based on their Octo Expansion outfit, take special photos on the Off the Hook stage, and get new music for Ika Radio!"
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                }
            ]
        },
        {
            "id": "1368233",
            "number": "ZZZZZZ",
            "name": "Pearl",
            "dateRelease": "2018-07-13T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "1173295",
                    "rank": "1",
                    "description": "You can unlock new gear based on their Octo Expansion outfit, take special photos on the Off the Hook stage, and get new music for Ika Radio!"
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1275928",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                }
            ]
        },
        {
            "id": "1401278",
            "number": "ATAA",
            "name": "Solaire of Astora",
            "dateRelease": "2018-10-19T00:00:00Z",
            "collection": "Other",
            "gameSeries": [
                "other"
            ],
            "compatibleGames": [
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1325790",
                    "rank": "1",
                    "description": "You can perform the \"Praise the Sun\" gesture at any time."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                }
            ]
        },
        {
            "id": "1401276",
            "number": "AEAP",
            "name": "Octoling girl",
            "dateRelease": "2018-11-09T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "1173295",
                    "rank": "3",
                    "description": "Stay tuned for amiibo compatibility information!"
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                }
            ]
        },
        {
            "id": "1401275",
            "number": "AEAQ",
            "name": "Octoling boy",
            "dateRelease": "2018-11-09T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "1173295",
                    "rank": "3",
                    "description": "Stay tuned for amiibo compatibility information!"
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                }
            ]
        },
        {
            "id": "1401277",
            "number": "AEAR",
            "name": "Octoling Octopus",
            "dateRelease": "2018-11-09T00:00:00Z",
            "collection": "Splatoon Collection",
            "gameSeries": [
                "splatoon"
            ],
            "compatibleGames": [
                {
                    "id": "1173295",
                    "rank": "3",
                    "description": "Stay tuned for amiibo compatibility information!"
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                }
            ]
        },
        {
            "id": "1404360",
            "number": "AACV",
            "name": "Wolf",
            "dateRelease": "2018-12-07T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "star_fox",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                }
            ]
        },
        {
            "id": "1403528",
            "number": "AACW",
            "name": "Ridley",
            "dateRelease": "2018-12-07T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1348073",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1136169",
                    "rank": "1",
                    "description": "You can find Secret Spots!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1225809",
                    "rank": "1",
                    "description": "You can get Game Tickets to use at the Arcade!"
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                }
            ]
        },
        {
            "id": "1403529",
            "number": "AACZ",
            "name": "Inkling",
            "dateRelease": "2018-12-07T00:00:00Z",
            "collection": "Super Smash Bros. Collection",
            "gameSeries": [
                "splatoon",
                "super_smash_bros"
            ],
            "compatibleGames": [
                {
                    "id": "892510",
                    "rank": "2",
                    "description": "You can unlock new missions, then beat them to acquire special weapons and gear."
                },
                {
                    "id": "1173295",
                    "rank": "2",
                    "description": "You can use this amiibo to make the figure's character appear in-game and befriend you. You can save your gear and settings to your amiibo and they might even give you a special item."
                },
                {
                    "id": "273841",
                    "rank": "3",
                    "description": "You can invite Splatoon characters to visit the campground in their campers, or receive a daily item. If a character comes to the campground, you can ask them to move in, or exchange items with them."
                },
                {
                    "id": "1125616",
                    "rank": "2",
                    "description": "You can unlock paint jobs for your Mech in Blast Ball!"
                },
                {
                    "id": "1173281",
                    "rank": "2",
                    "description": "You can unlock a Mii Suit based on that character's look!"
                },
                {
                    "id": "1025884",
                    "rank": "2",
                    "description": "You can unlock paint jobs and get a special advantage in battle!"
                },
                {
                    "id": "1348073",
                    "rank": "2",
                    "description": "You can unlock a special character-themed item!"
                },
                {
                    "id": "1225809",
                    "rank": "2",
                    "description": "You can unlock special character-themed outfits for your Mii characters!"
                },
                {
                    "id": "892704",
                    "rank": "2",
                    "description": "After using an amiibo, when Mario grabs a Mystery Mushroom he takes on the amiibo character's appearance, complete with character-specific animations and sound effects."
                },
                {
                    "id": "1348071",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1136171",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "892537",
                    "rank": "1",
                    "description": "You can unlock a Yoshi pattern."
                },
                {
                    "id": "1026451",
                    "rank": "1",
                    "description": "You can get coins or rings to use at the Item Stand!"
                },
                {
                    "id": "1109483",
                    "rank": "1",
                    "description": "You can get in-game coins!"
                },
                {
                    "id": "1213275",
                    "rank": "1",
                    "description": "You can receive a selection of fragments."
                },
                {
                    "id": "1081362",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1101346",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "986009",
                    "rank": "1",
                    "description": "You can unlock gameplay highlights from select NES and Super NES titles."
                },
                {
                    "id": "1054663",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1231536",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1101292",
                    "rank": "1",
                    "description": "You can play 12 basic levels as the toy robot Mini Spek!"
                },
                {
                    "id": "1173332",
                    "rank": "1",
                    "description": "You can reveal a Power Moon's location."
                },
                {
                    "id": "1021489",
                    "rank": "1",
                    "description": "You can earn extra moolah in-game!"
                },
                {
                    "id": "1233956",
                    "rank": "1",
                    "description": "You can unlock one Illustration Piece and additional items."
                },
                {
                    "id": "1175211",
                    "rank": "1",
                    "description": "You can summon a monster to assist you in battle."
                },
                {
                    "id": "1175315",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "892779",
                    "rank": "1",
                    "description": "You can play Scratch Bonus and earn Mario Party Points."
                },
                {
                    "id": "1025970",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "892923",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                },
                {
                    "id": "1173609",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "893260",
                    "rank": "1",
                    "description": "You can get a selection of items to help you on your adventure!"
                },
                {
                    "id": "1091342",
                    "rank": "1",
                    "description": "You can get a Copy Ability for Kirby!"
                },
                {
                    "id": "1175329",
                    "rank": "1",
                    "description": "You can receive a random amount of gold or rare materials."
                },
                {
                    "id": "891849",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1086374",
                    "rank": "1",
                    "description": "You can get extra items."
                },
                {
                    "id": "1313750",
                    "description": "You can obtain in-game currency, items or crafting materials."
                },
                {
                    "id": "1275928",
                    "rank": "1",
                    "description": "You can unlock a special item!"
                },
                {
                    "id": "1136172",
                    "rank": "1",
                    "description": "You can unlock in-game collectible cards!"
                },
                {
                    "id": "1325999",
                    "rank": "1",
                    "description": "You can get extra items, weapons and more."
                },
                {
                    "id": "1081363",
                    "rank": "1",
                    "description": "You can unlock special puzzles in-game!"
                },
                {
                    "id": "1348072",
                    "rank": "1",
                    "description": "You can boost your supply of 1-Ups."
                }
            ]
        }
    ]
}